<template>
    <b-modal ref="edit-main-modal" size="lg" hide-footer id="edit-main-modal" :no-close-on-backdrop="true" title="Редактирование основных данных">
        <step-one :edit-data="editData" :registration-data="registrationData" @userDataUpdated="userDataUpdatedListener"></step-one>
    </b-modal>
</template>

<script>
    import StepOne from "../../../views/auth/registration/step-one/StepOne";
    import {authService} from "../../../_api";

    export default {
        name: 'editMainModal',
        props: [''],
        data() {
            return {
                editData: null,
                registrationData: null
            }
        },
        components: {
            'step-one': StepOne
        },
        methods: {

            showModal(data) {
                // console.log(data);
                this.editData = data;
                this.$refs['edit-main-modal'].show();
            },
            userDataUpdatedListener() {
                this.$refs['edit-main-modal'].hide();
                this.$emit('userDataUpdated');
            }
        },
        beforeCreate() {},
        created() {
            authService.getRegData().then((res) => {
                this.registrationData = res;
            })
        }
    }
</script>

<style lang="less">
    #edit-main-modal {
        .modal-dialog {
            &.modal-xl {
                max-width: 960px;
            }
            .modal-content {
                .modal-header {
                    .modal-title {
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        color: rgba(51, 51, 51, 0.9);
                    }
                }
                .modal-body {
                    box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.15);
                    padding: 0;
                    .loading-wrapper {
                        width: 100%;
                        height: 80vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .modal-footer {

                }
            }
        }
    }
</style>

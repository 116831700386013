<template>
    <b-modal ref="change-password-modal" size="md" hide-footer id="change-password-modal" :no-close-on-backdrop="true" title="Изменить пароль">
        <div id="registration-password-setup">
            <div class="c-wrapper">
                <div class="form-wrapper">
                    <ValidationObserver v-slot="{invalid}" ref="form">
                        <form class="u-col-12" v-on:submit.prevent="handleSubmit()">
                            <div class="form-group">
                                <label for="password">
                                    Придумайте, пожалуста, новый пароль:
                                </label>
                                <ValidationProvider rules="required|min-max:8,15|strong|password:@confirm" v-slot="{classes, errors}">
                                    <div class="e-wrapper" v-show="isSubmitted && errors.length">
                                        <div class="err-wrapper">
                                            <div v-for="err in errors">{{ err }}</div>
                                        </div>
                                    </div>
                                    <div class="input-wrapper">
                                        <input ref="password" id="password" type="password" v-model="userData.password" name="password" class="form-control" :class="isSubmitted ? classes : ''" placeholder="Пароль"/>
                                    </div>
                                    <password-strength id="password" :password="userData.password"></password-strength>
                                </ValidationProvider>
                            </div>
                            <div class="form-group">
                                <ValidationProvider name="confirm" rules="required|min-max:8,15" v-slot="{classes, errors}">
                                    <div class="e-wrapper">
                                        <div class="err-wrapper" v-if="isSubmitted">
                                            <div v-for="err in errors">{{ err }}</div>
                                        </div>
                                    </div>
                                    <div class="input-wrapper">
                                        <input id="confirm" type="password" v-model="userData.password_conf" name="confirm" class="form-control" v-bind:class="isSubmitted ? classes : 'submitted'" placeholder="Подтверждение пароля"/>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="controls-wrapper">
                                <button class="def-btn submit-btn" type="submit">Изменить пароль</button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import {authService} from "../../../_api";
    import PasswordSetup from "../../../views/auth/registration/password-setup/PasswordSetup";
    import passwordStrength from "../../_shared/PasswordStrength";

    export default {
        name: 'changePasswordModal',
        props: [''],
        data() {
            return {
                isSubmitted: false,
                userData: {
                    password: '',
                    password_conf: '',
                    key: '',
                    username: ''
                }
            }
        },
        components: {
            'password-strength': passwordStrength
        },
        methods: {
            showModal(data) {
                // console.log(data);
                this.editData = data;
                this.$refs['change-password-modal'].show();
            },
            handleSubmit() {
                this.isSubmitted = true;
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        return;
                    } else {
                        authService.setPassword(this.userData).then((res) => {
                            if (res) {
                                this.$store.dispatch('authentication/editUserAuthToken', res.data.api_key);
                                this.$refs['change-password-modal'].hide();
                                this.$store.dispatch('alert/success', 'Пароль изменен', { root: true });
                            }
                        });
                    }
                })
            },
        },
        beforeCreate() {},
        created() {
            /*authService.getRegData().then((res) => {
                this.registrationData = res;
            })*/
        }
    }
</script>

<style lang="less">
    @import "../../../assets/styles/_vars";
    #change-password-modal {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    .modal-title {
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        color: rgba(51, 51, 51, 0.9);
                    }
                }
                .modal-body {
                    box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.15);
                    padding: 0;
                }
            }
        }
    }
    #registration-password-setup {
        .c-wrapper {
            background-color: #F8F8F8;
            -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            /*padding: 31px 249px 49px 117px!important;*/
            padding: 15px;
            .form-wrapper {
                width: 100%;
                display: flex;
                justify-content: center;
                &>span {
                    width: 100%;
                }
                form {
                    // max-width: 330px;
                    width: 100%;
                    background-color: transparent!important;
                    box-shadow: none!important;
                    // margin: 31px 249px 49px 117px!important;
                    padding: 0!important;
                    label {
                        font-size: 18px!important;
                        text-transform: none!important;
                        font-weight: 400!important;
                        margin-bottom: 20px;
                        color: #666666;
                        text-decoration: underline;
                    }
                    .form-group {
                        margin-bottom: 20px;
                        text-align: left;
                    }
                    .submit-btn {
                        width: 100%;
                    }
                    .e-wrapper {
                        position: inherit;
                        margin-bottom: 5px;
                    }
                    .controls-wrapper {
                        button {
                            font-size: 15px;
                            font-weight: 500;
                            padding: 10px 10px;
                        }
                    }
                }
            }
        }
    }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"change-password-modal",attrs:{"size":"md","hide-footer":"","id":"change-password-modal","no-close-on-backdrop":true,"title":"Изменить пароль"}},[_c('div',{attrs:{"id":"registration-password-setup"}},[_c('div',{staticClass:"c-wrapper"},[_c('div',{staticClass:"form-wrapper"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"u-col-12",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit()}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v(" Придумайте, пожалуста, новый пароль: ")]),_c('ValidationProvider',{attrs:{"rules":"required|min-max:8,15|strong|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSubmitted && errors.length),expression:"isSubmitted && errors.length"}],staticClass:"e-wrapper"},[_c('div',{staticClass:"err-wrapper"},_vm._l((errors),function(err){return _c('div',[_vm._v(_vm._s(err))])}),0)]),_c('div',{staticClass:"input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.password),expression:"userData.password"}],ref:"password",staticClass:"form-control",class:_vm.isSubmitted ? classes : '',attrs:{"id":"password","type":"password","name":"password","placeholder":"Пароль"},domProps:{"value":(_vm.userData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "password", $event.target.value)}}})]),_c('password-strength',{attrs:{"id":"password","password":_vm.userData.password}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required|min-max:8,15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"e-wrapper"},[(_vm.isSubmitted)?_c('div',{staticClass:"err-wrapper"},_vm._l((errors),function(err){return _c('div',[_vm._v(_vm._s(err))])}),0):_vm._e()]),_c('div',{staticClass:"input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.password_conf),expression:"userData.password_conf"}],staticClass:"form-control",class:_vm.isSubmitted ? classes : 'submitted',attrs:{"id":"confirm","type":"password","name":"confirm","placeholder":"Подтверждение пароля"},domProps:{"value":(_vm.userData.password_conf)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "password_conf", $event.target.value)}}})])]}}],null,true)})],1),_c('div',{staticClass:"controls-wrapper"},[_c('button',{staticClass:"def-btn submit-btn",attrs:{"type":"submit"}},[_vm._v("Изменить пароль")])])])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div id="my-profile">
        <div class="my-profile-header">
            <div class="my-profile-header__title">
                Ваш аккаунт
            </div>
            <div class="my-profile-header__controls">
                <div class="my-profile-header__controls__change-pwd u-underline" @click="showChangePasswordModal()">
                    Изменить пароль
                </div>
                <div class="my-profile-header__controls__logout u-underline" @click="logout()">
                    Выйти из аккаунта
                </div>
                <div class="my-profile-header__controls__more-actions"></div>
            </div>
        </div>
        <div class="my-profile-info-content" >
            <div class="content-block" v-if="user_data">
                <div class="column">
                    <div class="avatar-wrapper">
                        <avatar :width="111" :height="111" :img="myAvatar"></avatar>
                    </div>
                    <div class="user-fullname">
                        <span v-if="user_data">{{user_data.name}}</span>
                    </div>
                    <div class="arrow-link">
                        <span @click="goToMyAnswered()" class="link underline-controls underline-link"><span class="quantity">{{user_data.answered_count}}</span> отвеченных вопросов</span>
                        <!--<span><img src="../../assets/img/Line-right.svg" alt="right"></span>-->
                    </div>
                    <div class="arrow-link">
                        <span @click="goToMyNotAnswered()" class="link underline-controls underline-link"><span class="quantity">{{user_data.not_answered_count}}</span> неотвеченных вопросов</span>
                        <!--<span><img src="../../assets/img/Line-right.svg" alt="right"></span>-->
                    </div>
                    <div class="underline-link-wrapper">
                        <div class="underline-controls underline-link" @click="goToPQ()">{{user_data.my_personal_counter + ' персональных вопросов'}}</div>
                        <button href="" v-on:click="showModal" class="def-btn">Создать персональный вопрос</button>
                    </div>
                    <!--<div class="underline-link-wrapper">
                        <div class="underline-controls underline-link">{{2 + ' записи'}}</div>
                        <button href="" class="def-btn" style="margin-bottom: 0px;">Опубликовать запись</button>
                    </div>-->
                </div>
                <div class="column">
                    <div class="column-title">
                        <span class="title">Основные данные</span>
                        <span class="icon-wrapper">
                            <span class="icon" v-on:click="toggleMainEdit" v-if="!mainEdit"></span>
                            <span class="" v-on:click="toggleMainEdit" v-if="mainEdit">X</span>
                        </span>
                    </div>
                    <div class="column-item">
                        <div class="left-side">
                            <div class="property-name">Цель пребывания</div>
                            <div class="property-value" v-if="!user_data.goals.length">Без конкретной цели</div>
                            <div v-if="user_data.goals.length">
                                <div class="property-value" v-for="i in user_data.goals">{{i.name}}</div>
                            </div>
                        </div>
                        <div class="right-side">
                            <!--<visibility-item-select v-bind:visibilityType="user_data.goal.visibility" v-on:updateType="refreshVisibility($event, 'goal')" v-bind:isDisabled="!mainEdit" class="small-icon"></visibility-item-select>-->
                        </div>
                    </div>
                    <div class="column-item">
                        <div class="left-side">
                            <div class="property-name">Возраст</div>
                            <div class="property-value" v-if="user_data.birthday">{{moment().diff(user_data.birthday, 'years')}} <span v-if="user_data.age_range">({{user_data.age_range.from + ' - ' + user_data.age_range.to}})</span></div>
                        </div>
                        <div class="right-side" v-if="user_data.birthday">
                            <visibility-item-select v-bind:visibilityType="user_data.birthday_visibility" v-on:updateType="refreshVisibility($event, 'birthday_visibility')" v-bind:isDisabled="!mainEdit" class="small-icon"></visibility-item-select>
                        </div>
                    </div>
	                <div class="column-item">
		                <div class="left-side">
			                <div class="property-name">Пол</div>
			                <div class="property-value" v-if="user_data.sex">{{(user_data.sex === 'm') ? 'Мужчина' : 'Женщина'}}</div>
		                </div>
		                <div class="right-side" hidden>
			                <visibility-item-select v-bind:visibilityType="user_data.birthday_visibility" v-on:updateType="refreshVisibility($event, 'birthday_visibility')" v-bind:isDisabled="!mainEdit" class="small-icon"></visibility-item-select>
		                </div>
	                </div>
                    <div class="column-item">
                        <div class="left-side">
                            <div class="property-name">Место жительства</div>
                            <div class="property-value" v-if="user_data.location">
	                            <span v-if="user_data.location.region">{{user_data.location.region.name}}</span>
	                            <span v-if="user_data.location.city">, </span>
	                            <span v-if="user_data.location.city">{{user_data.location.city.name}}</span>
                            </div>
                        </div>
                        <div class="right-side" v-if="user_data.location" >
                            <visibility-item-select  v-bind:visibilityType="user_data.place_visibility" v-on:updateType="refreshVisibility($event, 'place_visibility')" v-bind:isDisabled="!mainEdit" class="small-icon"></visibility-item-select>
                        </div>
                    </div>
                    <div class="column-item">
                        <div class="left-side">
                            <div class="property-name">Email</div>
                            <div class="property-value">{{user_data.email}}</div>
                        </div>
                        <div class="right-side">
                            <visibility-item-select v-bind:visibilityType="user_data.email_visibility" v-on:updateType="refreshVisibility($event, 'email_visibility')" v-bind:isDisabled="!mainEdit" class="small-icon"></visibility-item-select>
                        </div>
                    </div>
                    <div class="column-item">
                        <div class="left-side" v-if="user_data.facebook !== null">
                            <div class="property-name">Facebook</div>
                            <div class="property-value">{{user_data.facebook}}</div>
                        </div>
                        <div class="left-side underline-link-wrapper" v-if="user_data.facebook === null">
                            <div class="property-name">Facebook</div>
                            <div class="underline-controls underline-link">Привязать</div>
                        </div>
                        <div class="right-side" v-if="user_data.facebook !== null">
                            <visibility-item-select v-bind:visibilityType="user_data.facebook_visibility" v-on:updateType="refreshVisibility($event, 'facebook')" v-bind:isDisabled="!mainEdit" class="small-icon"></visibility-item-select>
                        </div>
                    </div>
                    <div class="column-item">
                        <div class="left-side" v-if="user_data.vk !== null">
                            <div class="property-name">Vk</div>
                            <div class="property-value">{{user_data.vk}}</div>
                        </div>
                        <div class="left-side underline-link-wrapper" v-if="user_data.vk === null">
                            <div class="property-name">Vk</div>
                            <div class="underline-controls underline-link">Привязать</div>
                        </div>
                        <div class="right-side" v-if="user_data.vk !== null">
                            <visibility-item-select v-bind:visibilityType="user_data.vk_visibility" v-on:updateType="refreshVisibility($event, 'vk')" v-bind:isDisabled="!mainEdit" class="small-icon"></visibility-item-select>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="column-title">
                        <span class="title">Дополнительные данные</span>
                        <span class="icon-wrapper">
                            <span class="icon" v-on:click="toggleAddEdit" v-if="!additionalEdit"></span>
                            <span class="" v-on:click="toggleAddEdit" v-if="additionalEdit">X</span>
                        </span>
                    </div>
                    <div class="column-item">
                        <div class="left-side">
                            <div class="property-name">Ориентация</div>
                            <div class="property-value" v-if="user_data.orientation">{{user_data.orientation.name}}</div>
                        </div>
                        <div class="right-side" v-if="user_data.orientation">
                            <visibility-item-select v-bind:visibilityType="user_data.orientation_visibility" v-on:updateType="refreshVisibility($event, 'orientation_visibility')" v-bind:isDisabled="!additionalEdit" class="small-icon"></visibility-item-select>
                        </div>
                    </div>
                    <div class="column-item">
                        <div class="left-side">
                            <div class="property-name">Семейное положение</div>
                            <div class="property-value" v-if="user_data.family_status">{{user_data.family_status.name}}</div>
                        </div>
                        <div class="right-side" v-if="user_data.family_status">
                            <visibility-item-select v-bind:visibilityType="user_data.family_status_visibility" v-on:updateType="refreshVisibility($event, 'family_status_visibility')" v-bind:isDisabled="!additionalEdit" class="small-icon"></visibility-item-select>
                        </div>
                    </div>
                    <div class="column-item">
                        <div class="left-side">
                            <div class="property-name">Дети</div>
                            <div class="property-value" v-if="user_data.children">{{user_data.children.name}}</div>
                        </div>
                        <div class="right-side" v-if="user_data.children">
                            <visibility-item-select v-bind:visibilityType="user_data.children_visibility" v-on:updateType="refreshVisibility($event, 'children_visibility')" v-bind:isDisabled="!additionalEdit" class="small-icon"></visibility-item-select>
                        </div>
                    </div>
                    <div class="column-item">
                        <div class="left-side">
                            <div class="property-name">Образование</div>
                            <div class="property-value" v-if="user_data.education">{{user_data.education.name}}</div>
                        </div>
                        <div class="right-side" v-if="user_data.education">
                            <visibility-item-select v-bind:visibilityType="user_data.education_visibility" v-on:updateType="refreshVisibility($event, 'education_visibility')" v-bind:isDisabled="!additionalEdit" class="small-icon"></visibility-item-select>
                        </div>
                    </div>
                    <div class="column-item">
                        <div class="left-side">
                            <div class="property-name">Занятость</div>
                            <div class="property-value" v-if="user_data.work_status">{{user_data.work_status.name}}</div>
                        </div>
                        <div class="right-side" v-if="user_data.work_status">
                            <visibility-item-select v-bind:visibilityType="user_data.work_status_visibility" v-on:updateType="refreshVisibility($event, 'work_status_visibility')" v-bind:isDisabled="!additionalEdit" class="small-icon"></visibility-item-select>
                        </div>
                    </div>
                    <div class="column-item">
                        <div class="left-side" v-if="user_data.occupations.length">
                            <div class="property-name">Род занятий</div>
                            <div class="property-value" v-for="i in user_data.occupations">{{i.name}}</div>
                        </div>
                        <div class="left-side underline-link-wrapper" v-if="!user_data.occupations.length">
                            <div class="property-name">Род занятий</div>
                            <div class="underline-controls underline-link" v-on:click="toggleAddEdit">Указать</div>
                        </div>
                        <div class="right-side" v-if="user_data.occupations.length">
                            <visibility-item-select v-bind:visibilityType="user_data.occupation_visibility" v-on:updateType="refreshVisibility($event, 'occupation_visibility')" v-bind:isDisabled="!additionalEdit" class="small-icon"></visibility-item-select>
                        </div>
                    </div>
                    <div class="column-item">
                        <div class="left-side" v-if="user_data.interests && user_data.interests.length">
                            <div class="property-name">Интересы</div>
                            <div class="property-value" v-for="i in user_data.interests">{{i.name}}</div>
                        </div>
                        <div class="left-side underline-link-wrapper" v-if="user_data.interests && !user_data.interests.length">
                            <div class="property-name">Интересы</div>
                            <div class="underline-controls underline-link" v-on:click="toggleAddEdit">Указать</div>
                        </div>
                        <div class="right-side" v-if="user_data.interests && user_data.interests.length">
                            <visibility-item-select v-bind:visibilityType="user_data.interest_visibility" v-on:updateType="refreshVisibility($event, 'interest_visibility')" v-bind:isDisabled="!additionalEdit" class="small-icon"></visibility-item-select>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="content-block">
                <div class="column-left">
                    <h2 class="title">Психологические особенности</h2>
                    <p class="subtitle">По сравнению со средним пользователем</p>
                </div>
                <div class="column-right">
                    <div class="range-data-wrapper">
                        <div class="range-data-item" v-for="item in user_features">
                            <div class="psychological-feature">{{item.description}}</div>
                            <div class="range-wrapper">
                                <div class="value-bar">
                                    <div class="slider" v-bind:style="{ left: item.value + '%', backgroundColor: item.color }"></div>
                                </div>
                            </div>
                            <div class="visibility-wrapper">
                                <visibility-item-select v-bind:visibilityType="item.visibility" v-on:updateType="refreshVisibilityFeatures($event, item)" class="only-small-icon" v-bind:isDisabled="!featuresEdit"></visibility-item-select>
                            </div>
                        </div>
                        <div class="underline-link edit-visibility" @click="toggleFeaturesEdit">Редактировать видимость <span v-if="featuresEdit" @click="toggleFeaturesEdit">X</span></div>
                    </div>
                </div>
            </div>-->
        </div>

        <gallery-section></gallery-section>

        <div hidden class="my-profile-posts-content">
            <div class="section-title">
                <h2>Ваши записи</h2>
            </div>
            <div class="content-wrapper">
                <div class="add-post">
                    <div class="post-header">
                        <div class="post-header-title" v-if="userData">
                            <!--<img :src="userData.avatar_parsed || require('../../assets/img/7VE.gif')" alt="avatar">-->
                            <avatar :width="36" :height="36" :img="userData.avatar_parsed"></avatar>
                        </div>
                        <div class="post-add-wrapper">
                            <textarea name="" id="" cols="30" rows="1" placeholder="Напишите что-небудь, чем вы хотели поделиться"></textarea>
                        </div>
                        <visibility-item-select v-bind:visibilityType="1" class="small-icon"></visibility-item-select>
                    </div>
                </div>
                <div class="post-item-wrapper">
                    <div class="post-item" v-for="post in user_posts">
                        <div class="post-header">
                            <div class="post-header-title" v-if="userData">
                                <!--<img :src="userData.avatar_parsed || require('../../assets/img/7VE.gif')" alt="avatar">-->
                                <avatar :width="36" :height="36" :img="userData.avatar_parsed"></avatar>
                                <div class="text-wrapper">
                                    <p class="user-name">Вы</p>
                                    <p class="post-type-title">сделали запись</p>
                                </div>
                            </div>
                            <div class="post-header-time">
                                {{post.created_at}}
                            </div>
                        </div>
                        <div class="post-body-wrapper">
                            <div class="post-body">
                                <p>{{post.content}}</p>
                                <!-- <div class="post-body-tags">
                                    <div class="tag" v-for="tag of item.post_tags">
                                        {{tag.name}}
                                    </div>
                                </div>
                                <div class="post-body-main-title">
                                    {{item.post_title}}
                                </div> -->
                            </div>
                        </div>
                        <div class="post-footer">
                            <div class="post-counters">
                                <div class="post-footer-likes">
                                    <span class="icon"></span>
                                    <span>{{post.likes}}</span>
                                </div>
                                <div class="post-footer-comments">
                                    <span class="icon"></span>
                                    <span>{{post.comments}}</span>
                                </div>
                                <div class="post-footer-visibility">
                                    <span class="icon" v-bind:class="{ 'all': post.visibility === 1, 'nobody': post.visibility === 0, 'friends': post.visibility === 2}"></span>
                                    <span>{{post.visibility_desc}}</span>
                                </div>
                            </div>
                            <div class="post-actions">
                                <button class="ask-friends">Поделиться</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-personal-question-modal ref="createPersonalQuestionRef" :custom-visibility-list="customVisibilityList"></create-personal-question-modal>
        <edit-main-modal @userDataUpdated="userDataUpdatedListener" ref="editMainModal"></edit-main-modal>
        <edit-additional-modal @userDataUpdated="userDataUpdatedListener" ref="editAdditionalModal"></edit-additional-modal>
        <change-password-modal ref="changePasswordRef"></change-password-modal>

        <!--<imageSlider :img-index="img_index" :img-array="(user_gallery ? arr : galleryPreview)" v-show="isModalVisible" @close="closeModal1" :is-open="isModalVisible"></imageSlider>-->
        <!--<imageSlider ref="imageSlider" :img-index="img_index" :img-array="gallery" @close="closeModal1" :is-open="isModalVisible" :has-comments-section="true" :is-slider="true"></imageSlider>
        <upload-image-modal ref="upload-image-modal" @imagesAdded="imagesAdded"></upload-image-modal>-->
    </div>
</template>

<script>
    import createPersonalQuestionModal from '../../../components/_shared/modals/_CreatePersonalQuestionModal';
    import editMainModal from '../../../components/users/my-profile/_EditMainModal';
    import editAdditionalModal from '../../../components/users/my-profile/_EditAdditionalModal';
    import changePasswordModal from '../../../components/users/my-profile/_ChangePasswordModal';
    import visibilityItemSelect from '../../../components/_shared/VisibilityItemSelect';
    import {authService, questionsService, usersService} from "../../../_api";
    import avatar from "../../../components/_shared/Avatar";
    import Loading from "../../../components/_shared/Loading";
    import imageSlider from "../../../components/_shared/ImageSlider";
    import UploadImageModal from "../../../components/_shared/modals/_UploadImageToGallery";
    import gallerySection from "../../../components/_shared/GallerySection";
    import {handleAWSImage} from "../../../_helpers/aws-handler";

    export default {
        name: 'my-profile',
        data() {
            return {
                mainEdit: false,
                additionalEdit: false,
                featuresEdit: false,
                user_features: [
                    {
                        value: 80,
                        description: 'Доброжелательность',
                        visibility: 1
                    },
                    {
                        value: 70,
                        description: 'Терпимость',
                        visibility: 1
                    },
                    {
                        value: 60,
                        description: 'Контактность',
                        visibility: 1
                    },
                    {
                        value: 50,
                        description: 'Сексуальная раскованность',
                        visibility: 2
                    },
                    {
                        value: 40,
                        description: 'Ответственность',
                        visibility: 1
                    },
                    {
                        value: 30,
                        description: 'Рациональность',
                        visibility: 1
                    },
                    {
                        value: 20,
                        description: 'Ревнивость',
                        visibility: 0
                    },
                    {
                        value: 10,
                        description: 'Самодостаточность',
                        visibility: 1
                    }
                ],
                user_posts: [
                    {
                        user_id: 10,
                        created_at: this.moment(this.moment().subtract(4, 'hours')).fromNow(),
                        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora dolore assumenda aliquid architecto maxime, vel quae placeat similique voluptate voluptas in dignissimos molestiae repudiandae, distinctio mollitia aut sunt? Maiores, soluta.',
                        likes: 12,
                        comments: 13,
                        visibility: 1
                    },
                    {
                        user_id: 10,
                        created_at: this.moment(this.moment().subtract(8, 'hours')).fromNow(),
                        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. ',
                        likes: 8,
                        comments: 5,
                        visibility: 0
                    }
                ],
                user_data: null,
                customVisibilityList: [
                    {
                        description: 'Друзья Up&Up',
                        class: null,
                        type: 1
                    },
                    {
                        description: 'Выбранные пользователи',
                        class: null,
                        type: 2
                    }
                ]
            }
        },
        components: {
            gallerySection,
            Loading,
            'edit-main-modal': editMainModal,
            'edit-additional-modal': editAdditionalModal,
            'change-password-modal': changePasswordModal,
            'create-personal-question-modal': createPersonalQuestionModal,
            'visibility-item-select': visibilityItemSelect,
            avatar
        },
        beforeCreate() {},
        created() {
            this.user_posts.forEach(function(obj) {
                if (obj.visibility === 0) {
                    obj['visibility_desc'] = 'Никто'
                } else if (obj.visibility === 1) {
                    obj['visibility_desc'] = 'Все'
                } else if (obj.visibility === 2) {
                    obj['visibility_desc'] = 'Друзья Up&Up'
                } else {
                    obj['visibility_desc'] = ''
                }
            });
            this.user_features.forEach(function(obj) {
                if (obj.value <= 10) {
                    obj.color = '#12354e';
                } else if (10 < obj.value && obj.value <= 20) {
                    obj.color = '#1A5076';
                } else if (20 < obj.value && obj.value <= 30) {
                    obj.color = '#276897';
                } else if (30 < obj.value && obj.value <= 40) {
                    obj.color = '#2870A3';
                } else if (40 < obj.value && obj.value <= 50) {
                    obj.color = '#3988BF';
                } else if (50 < obj.value && obj.value <= 60) {
                    obj.color = '#47A1E0';
                } else if (60 < obj.value && obj.value <= 70) {
                    obj.color = '#4CABEE';
                } else if (70 < obj.value && obj.value <= 80) {
                    obj.color = '#78C4FA';
                } else if (80 < obj.value && obj.value <= 90) {
                    obj.color = '#9ED5FB';
                } else if (90 < obj.value && obj.value <= 100) {
                    obj.color = '#c6e5fb';
                }
            });
        },
        mounted() {
            this.getUserData();
        },
        watch: {},
        computed: {
            userData() {
                return this.$store.state.authentication.user;
            },
            myAvatar() {
                return this.$store.state.authentication.user_avatar
            },
        },
        methods: {
            goToMyNotAnswered() {
                this.$router.push({path: '/questions/main-questions', query: {tab: 0}});
            },
            goToMyAnswered() {
                this.$router.push({path: '/questions/main-questions', query: {tab: 2}});
            },
            goToPQ() {
                this.$router.push({path: '/questions/personal-questions', query: {tab: 0}});
            },
            getUserData() {
                return new Promise(((resolve, reject) => {
                    questionsService.getUserProfileData({
                        user_id: this.userData.id
                    }).then((res) => {
                        this.user_data = res;
                        this.user_data['facebook'] = null;
                        this.user_data['vk'] = null;
                        this.$forceUpdate();
                        resolve(this.user_data);
                    })
                }))
            },
            userDataUpdatedListener() {
                this.getUserData().then((res) => {
                    this.$store.dispatch('authentication/updateUserData', res);
                })
            },

            logout() {
                this.$store.dispatch('authentication/logout')
			},

            showModal() {
                this.$refs.createPersonalQuestionRef.showModal();
                this.$store.dispatch('question/getTopics', 8);
            },
            showQuestionsModal() {
                this.$refs.answerTheQuestionsRef.showModal();
            },
            showChangePasswordModal() {
                this.$refs.changePasswordRef.showModal();
            },

            toggleMainEdit() {
                this.$refs.editMainModal.showModal(this.user_data);
                // this.mainEdit = !this.mainEdit;
            },
            toggleAddEdit() {
                this.$refs.editAdditionalModal.showModal(this.user_data);
               //  this.additionalEdit = !this.additionalEdit;
            },
            toggleFeaturesEdit() {
                this.featuresEdit = !this.featuresEdit;
            },
            refreshVisibility(e, key) {
                this.user_data[key] = e;
            },
            refreshVisibilityFeatures(e, item) {
                for (let i = 0; i < this.user_features.length; i++) {
                    if (this.user_features[i].description === item.description) {
                        this.user_features[i].visibility = e;
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../../assets/styles/_vars.less";
    ::v-deep #visibility-item-select {
	    width: 40px!important;
	    .selected-item {
		    .description {
			    line-height: 10px!important;
		    }
	    }
    }
    #my-profile {
        .my-profile-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            .my-profile-header__title {
                font-size: 14px;
                line-height: 24px;
                text-align: right;
                letter-spacing: -0.14px;
                text-transform: uppercase;
                color: #666666;
            }
            .my-profile-header__controls {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;
                &>div {
                    font-size: 12px;
                    font-weight: 400;
                    position: relative;
                    cursor: pointer;
                    .more-actions {
                        span {
                            transform: rotate(90deg);
                        }
                    }
                    &:not(:last-child) {
                        margin-right: 15px;
                    }
                }
            }
        }
        .my-profile-info-content {
            width: 100%;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 1px;
            margin-bottom: 34px;
            .content-block {
                width: 100%;
                display: flex;
                justify-content: space-between;
                background: #fff;
                padding: 34px;
                margin-bottom: 2px;
                &:first-child {
                    margin-top: 34px;
                }
                .column {
                    width: 30%;
                    .avatar-wrapper {
                        /*width: 111px;
                        height: 111px;
                        overflow: hidden;
                        border-radius: 50%;*/
                        margin-bottom: 14px;
                        .image-as-bg {
                            margin: 0 auto;
                        }
                        /*display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            height: 100%;
                            width: 100%;
                        }*/
                    }
                    .user-fullname {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        letter-spacing: 0.2px;
                        margin: 0 auto 21px;
                    }
                    .arrow-link {
                        font-size: 13px;
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 17px;
                        .link {
                            cursor: pointer;
                        }
                        .green-text {
                            color: #5EBD9C;
                        }
                        .quantity {
                            font-size: 12px;
                        }
                    }
                    .underline-link-wrapper {
                        text-align: left;
                        .underline-controls {
                            font-size: 12px;
                            line-height: 14px;
                            margin-bottom: 17px;
                            display: inline-block;
                        }
                        .def-btn {
                            margin-bottom: 14px;
                            font-size: 9px;
                            width: 100%;
                        }
                    }
                    .column-title {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        .title {
                            font-weight: 500;
                            font-size: 11px;
                            line-height: 24px;
                            letter-spacing: -0.11px;
                            text-transform: uppercase;
                            color: #666666;
                        }
                        .icon-wrapper {
                            min-width: 25px;
                            text-align: center;
                            .icon {
                                background: transparent url('../../../assets/img/pencil-blue.svg') center no-repeat;
                                width: 12px;
                                height: 12px;
                                display: inline-flex;
                                cursor: pointer;
                            }
                        }
                    }
                    .column-item {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 14px;
                        text-align: left;
                        .left-side {
                            width: 100%;
                            .property-name {
                                font-size: 11px;
                                line-height: 18px;
                                letter-spacing: 0.0846154px;
                                color: #666666;
                            }
                            .property-value {
                                font-size: 13px;
                                line-height: 18px;
                                letter-spacing: 0.1px;
                                word-break: break-word;
                            }
                        }
                        .right-side {
                            width: auto;
                            min-width: 25px;
                            display: flex;
                            text-align: center;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            .icon {
                                display: inline-flex;
                                width: 12px;
                                height: 12px;
                                &.all {
                                    background: transparent url('../../../assets/img/eye-icon.svg') center no-repeat;
                                    background-size: contain;
                                }
                                &.nobody {
                                    background: transparent url('../../../assets/img/secret.svg') center no-repeat;
                                    background-size: contain;
                                }
                                &.friends {
                                    background: transparent url('../../../assets/img/circle.svg') center no-repeat;
                                    background-size: 8px;
                                }
                            }
                            .description {
                                font-size: 8px;
                                line-height: 18px;
                                letter-spacing: 0.0615385px;
                                color: #666666;
                            }
                        }
                    }
                }
                .column-left {
                    width: 30%;
                    text-align: left;
                    .title {
                        font-size: 22px;
                        line-height: 24px;
                        letter-spacing: 0.22px;
                    }
                    .subtitle {
                        font-size: 13px;
                        line-height: 15px;
                        color: #999999;
                    }
                }
                .column-right {
                    width: 65%;
                    padding-top: 20px;
                    .range-data-wrapper {
                        text-align: right;
                        .range-data-item {
                            display: flex;
                            justify-content: flex-end;
                            padding-bottom: 10px;
                            &:first-child {
                                .range-wrapper {
                                    position: relative;
                                    &:before {
                                        content: 'Меньше';
                                        position: absolute;
                                        left: 0px;
                                        top: -20px;
                                        font-size: 11px;
                                        line-height: 18px;
                                        letter-spacing: 0.0846154px;
                                        color: #666
                                    }
                                    &:after {
                                        content: 'Больше';
                                        position: absolute;
                                        right: 0px;
                                        top: -20px;
                                        font-size: 11px;
                                        line-height: 18px;
                                        letter-spacing: 0.0846154px;
                                        color: #666
                                    }
                                }
                            }
                            .psychological-feature {
                                font-size: 13px;
                                line-height: 18px;
                                letter-spacing: 0.1px;
                                color: @theme_color;
                            }
                            .range-wrapper {
                                margin-left: 30px;
                                padding-top: 8px;
                                .value-bar {
                                    width: 100%;
                                    min-width: 140px;
                                    position: relative;
                                    height: 1px;
                                    background: #666;
                                    .slider {
                                        position: absolute;
                                        left: 0;
                                        width: 10px;
                                        height: 10px;
                                        margin: -5px 0 0 -5px;
                                        border-radius: 50%;
                                        background: #666;
                                    }
                                }
                            }
                            .visibility-wrapper {
                                margin-left: 25px;
                                span {
                                    &.icon {
                                        display: inline-flex;
                                        width: 15px;
                                        height: 15px;
                                        &.all {
                                            background: transparent url('../../../assets/img/eye-icon.svg') center no-repeat;
                                            background-size: contain;
                                        }
                                        &.nobody {
                                            background: transparent url('../../../assets/img/secret.svg') center no-repeat;
                                            background-size: contain;
                                        }
                                        &.friends {
                                            background: transparent url('../../../assets/img/circle.svg') center no-repeat;
                                            background-size: 8px;
                                        }
                                    }
                                }
                            }
                        }
                        .edit-visibility {
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                }
            }
        }
        .my-profile-posts-content {
            width: 100%;
            .section-title {
                margin: 26px;
                text-align: left;
                h2 {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                    text-transform: uppercase;
                    color: #666666;
                    margin: 0;
                }
            }
            .content-wrapper {
                width: 70%;
                .add-post {
                    box-shadow: 0 0 6px -4px black;
                    border-radius: 1px;
                    background: #FFFFFF;
                    margin-bottom: 24px;
                    .post-header {
                        padding: 20px 38px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .post-header-title {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: wrap;
                            .image-as-bg {
                                /*width: 36px;
                                height: 36px;
                                border-radius: 100%;*/
                                margin-right: 13px;
                            }
                        }
                        .post-add-wrapper {
                            width: 100%;
                            textarea {
                                    outline: none;
                                    border: none;
                                    font-size: 12px;
                                    width: 100%;
                                    padding: 3px 5px;
                                    resize: none;
                                    overflow: hidden;
                                    height: 100%;
                                    vertical-align: middle;
                                &:placeholder {
                                    font-size: 12px;
                                    line-height: 14px;
                                    color: #999999;
                                }
                            }
                        }
                        .post-visibility {
                            font-size: 8px;
                            line-height: 18px;
                            color: #666666;
                            font-weight: 400;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            span {
                                &.icon {
                                    display: inline-flex;
                                    width: 12px;
                                    height: 9px;
                                    background: transparent url("../../../assets/img/eye-icon.svg") center no-repeat;
                                    background-size: contain;
                                }
                            }
                        }
                    }
                }
                .post-item-wrapper {
                    padding-bottom: 18px;
                    margin-bottom: 24px;
                    .post-item {
                        box-shadow: 0 0 6px -4px black;
                        border-radius: 1px;
                        background: #FFFFFF;
                        margin-bottom: 24px;
                        .post-header {
                            padding: 20px 38px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .post-header-title {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-wrap: wrap;
                                .image-as-bg {
                                    /*width: 36px;
                                    height: 36px;
                                    border-radius: 100%;*/
                                    margin-right: 13px;
                                }
                                .text-wrapper {
                                    display: flex;
                                    align-items: flex-end;
                                    p {
                                        margin: 0;
                                        &.user-name {
                                            font-size: 13px;
                                            font-weight: 500;
                                            margin-right: 5px;
                                        }
                                        &.post-type-title {
                                            font-size: 12px;
                                            font-weight: 400;
                                            color: #333333e6;
                                        }
                                    }
                                }
                            }
                            .post-header-time {
                                font-size: 11px;
                                color: #999999;
                                font-weight: 400;
                            }
                        }
                        .post-body-wrapper {
                            .post-body {
                                padding: 0px 38px;
                                text-align: left;
                                font-size: 13px;
                                line-height: 18px;
                                letter-spacing: 0.1px;
                            }
                        }
                        .post-footer {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: wrap;
                            padding: 20px 38px;
                            .post-counters {
                                display: flex;
                                .post-footer-likes {
                                    display: flex;
                                    margin-right: 15px;
                                    font-size: 12px;
                                    font-weight: 500;
                                    align-items: center;
                                    span {
                                        &.icon {
                                            width: 24px;
                                            height: 24px;
                                            background-image: url("../../../assets/img/Radio button gray.svg");
                                            display: flex;
                                        }
                                    }
                                }
                                .post-footer-comments {
                                    display: flex;
                                    font-size: 12px;
                                    margin-right: 15px;
                                    font-weight: 500;
                                    align-items: center;
                                    span {
                                        &.icon {
                                            display: flex;
                                            width: 24px;
                                            height: 24px;
                                            background-image: url("../../../assets/img/Comment Gray.svg");
                                            margin-right: 3px;
                                        }
                                    }
                                }
                                .post-footer-visibility {
                                    display: flex;
                                    font-size: 12px;
                                    font-weight: 500;
                                    align-items: center;
                                    span {
                                        &.icon {
                                            display: flex;
                                            margin-right: 3px;
                                            &.all {
                                                width: 24px;
                                                height: 24px;
                                                background: transparent url('../../../assets/img/eye-icon.svg') center no-repeat;
                                                background-size: 20px;
                                            }
                                            &.nobody {
                                                width: 16px;
                                                height: 24px;
                                                background: transparent url('../../../assets/img/secret.svg') center no-repeat;
                                                background-size: 14px;
                                            }
                                            &.friends {
                                                width: 16px;
                                                height: 24px;
                                                background: transparent url('../../../assets/img/circle.svg') center no-repeat;
                                                background-size: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                            .post-actions {
                                button {
                                    text-transform: uppercase;
                                    border-radius: 35px;
                                    border: 1px solid lightgrey;
                                    background-color: transparent;
                                    color: #333333;
                                    text-align: center;
                                    padding: 7px 30px;
                                    font-size: 10px;
                                    font-weight: 500;
                                    &:focus {
                                        outline: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @keyframes fade {
        from { opacity: 0; }
        to   { opacity: 1; }
    }



    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {
        #my-profile {
            .my-profile-info-content {
                .content-block {
                    flex-wrap: wrap;
                    .column {
                        &:first-child {
                            width: 100%;
                            margin-bottom: 20px;
                            .arrow-link, .underline-link-wrapper {
                                width: 50%;
                                margin: 0 auto 17px auto;
                            }
                        }
                        width: 45%;
                    }
                }
            }
        }
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {

    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #my-profile {
            .my-profile-info-content {
                .content-block {
                    flex-wrap: wrap;
                    padding: 20px;
                    .column {
                        width: 100%!important;
                        &:first-child {
                            .arrow-link, .underline-link-wrapper {
                                width: 100% !important;
                            }
                        }
                        &:not(:last-child) {
                            margin-bottom: 20px;
                        }
                        .column-item {
                            margin-bottom: 10px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            .underline-link-wrapper {
                                .underline-controls {
                                    margin-bottom: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
